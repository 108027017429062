
import Vue from "vue";

export default Vue.extend({
	name: "LayoutError",
	data: () => ({}),
	created() {},
	computed: {},
	methods: {},
});
